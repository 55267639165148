#home {
  position: relative;
  background: url("../../assets/bgIMG.png");
  background-size: cover;
  background-position: center;
  background-repeat: repeat;

  // disable the default padding styles
  .app__wrapper {
    padding: 0;

    .copyright {
      display: none;
    }
  }
}

.app__header {
  // flex: 1;
  width: 100%;
  height: 97%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 6rem 2rem 0rem;

  @media screen and (min-width: 2000px) {
    padding-top: 8rem;
  }

  @media screen and (max-width: 1200px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  @media screen and (max-width: 450px) {
    padding: 6rem 1rem 2rem;
  }
}

.app__header-info {
  flex: .65;
  display: flex;
  flex-direction: column;
  justify-content:center;
  align-items: flex-start;
  margin-bottom: 70px;
  height: 100%;

  margin: 0 2rem;
  h4{
    font-size:26px;;
    margin-bottom: 5px;
  }
  h2{
    font-size: 46px;
    margin-bottom: 5px;

  }
  p{
    color: rgb(107, 103, 103);
  }
  @media screen and (max-width: 1200px) {
    width: 100%;
    margin-right: 0rem;
    align-items: center;
    margin-bottom: 20px;
  }
}

.app__header-btn{
  margin-top: 20px;
  color:white;
  padding: 10px;
  width:130px;
  
  text-decoration: none;
  font-weight: 700;
  border-radius: 5px;
  transition: all 0.2s cubic-bezier(0.075, 0.82, 0.165, 1) ease-in-out;

  box-shadow: -3px 4px 43px 0px rgba(32,28,204,0.75);
-webkit-box-shadow: -3px 4px 43px 0px rgba(32,28,204,0.75);
-moz-box-shadow: -3px 4px 43px 0px rgba(32,28,204,0.75);
  background-color: var(--secondary-color);

 &:hover{
   cursor: pointer;
   transform: scale(1.04);
 }
}

.app__header-right{
  flex:1;
  display: flex;
  width: 100%;
  height:100%;
  flex-direction: column;
  .icon{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
   
    margin-top: 10px;
    margin-bottom: 20px;
    .img_cnt{
      margin-top: 25px;
      width:50px;
      margin-left: 5px;
      background-color: #ffffff;
      height:50px;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 1px 4px 32px -2px rgba(0,0,0,0.75);
-webkit-box-shadow: 1px 4px 32px -2px rgba(0,0,0,0.75);
-moz-box-shadow: 1px 4px 32px -2px rgba(0,0,0,0.75);
transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) ease-in-out;      
img{
        height: 40px;
        width: 40px;
      }

      &:hover{
        cursor: pointer;
        transform: scale(1.02);
      }
    }
  }
}

.app__header-box{
  flex:1;
display: flex;
width: 100%;
height:500px;
position: relative;

.box__wrapper{
  width: 95%;
  height:450px;
  background-color: rgba(12, 24, 47,0.9);
  border-radius: 5px;
  box-shadow: -3px 4px 13px 0px rgba(14,22,66,0.75);
-webkit-box-shadow: -3px 4px 13px 0px rgba(14,22,66,0.75);
-moz-box-shadow: -3px 4px 13px 0px rgba(14,22,66,0.75);

@media screen and (max-width: 1200px) {
  
  height: 500px;
 
}
@media screen and (max-width: 500px) {
  
  width: 100%;
 
}
}
.box{
  position: absolute;
  left: 40px;;
  height:460px;
  width: 95%;;
  border-radius: 5px;;
  top: 5px;
  background-color:rgb(10, 25, 53) ;
  box-shadow: -1px 8px 13px 0px rgba(8,18,48,0.75);
  -webkit-box-shadow: -1px 8px 13px 0px rgba(8,18,48,0.75);
  -moz-box-shadow: -1px 8px 13px 0px rgba(8,18,48,0.75);
 
  @media screen and (max-width: 1200px) {
  
    height: 500px;
   
  }
  @media screen and (max-width: 500px) {
  
    width: 100%;
    left:10px;
  }
 
}


@media screen and (max-width: 1200px) {
  
  margin-top: 10px;
  margin-bottom: 20px;
 
}

}

// Highlight

/**
 * Shades of Purple Theme — for Highlightjs.
 *
 * @author (c) Ahmad Awais <https://twitter.com/mrahmadawais/>
 * @link GitHub Repo → https://github.com/ahmadawais/Shades-of-Purple-HighlightJS
 * @version 1.5.0
 */

 .hljs {
  display: block;
  overflow-x: auto;
  height: 100%;;
  margin-bottom: 10px;
  /* Custom font is optional */
  /* font-family: 'Operator Mono', 'Fira Code', 'Menlo', 'Monaco', 'Courier New', 'monospace';  */
  padding: 1em;
  // background: #2d2b57;
  font-weight: normal;
  
  font-size: 16px;
  span{
    margin-top: 4px;;
    margin-bottom: 7px;;
  }
  
  
  
}

.hljs-title {
  color: #fad000;
  font-weight: normal;
}

.hljs-name {
  color: #a1feff;
}

.hljs-tag {
  color: #ffffff;
}

.hljs-attr {
  color: #f8d000;
  font-style: italic;
}

.hljs-built_in,
.hljs-selector-tag,
.hljs-section {
  color: #fb9e00;
}

.hljs-keyword {
  color: #fb9e00;
}

.hljs,
.hljs-subst {
  color: #e3dfff;
}

.hljs-string,
.hljs-attribute,
.hljs-symbol,
.hljs-bullet,
.hljs-addition,
.hljs-code,
.hljs-regexp,
.hljs-selector-class,
.hljs-selector-attr,
.hljs-selector-pseudo,
.hljs-template-tag,
.hljs-quote,
.hljs-deletion {
  color: #4cd213;
}

.hljs-meta,
.hljs-meta-string {
  color: #fb9e00;
}

.hljs-comment {
  color: #ac65ff;
}

.hljs-keyword,
.hljs-selector-tag,
.hljs-literal,
.hljs-name,
.hljs-strong {
  font-weight: normal;
}

.hljs-literal,
.hljs-number {
  color: #fa658d;
}

.hljs-emphasis {
  font-style: italic;
}

.hljs-strong {
  font-weight: bold;
}
